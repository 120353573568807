(function () {
    var el = document.querySelector('[data-el="shop-link"]');
    var eventName = whichAnimationEvent();

    el.addEventListener(eventName, function (event) {
        document.body.className += ' loaded';
        event.target.removeEventListener(event.type, arguments.callee);
    });

    function whichAnimationEvent(){
        var t;
        var el = document.createElement("fakeelement");

        var animations = {
            "animation"      : "animationend",
            "OAnimation"     : "oAnimationEnd",
            "MozAnimation"   : "animationend",
            "WebkitAnimation": "webkitAnimationEnd"
        }

        for (t in animations){
            if (el.style[t] !== undefined){
              return animations[t];
            }
        }
    }

})();
